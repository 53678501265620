import auth_http from '@/utils/auth_http'
import api from '@/api'

export default function getCosAuth (key, method) {
  return new Promise((resolve, reject) => {
    auth_http({
      url: api.ADMIN_COS_AUTH_URL,
      method: 'GET',
      params: {
        key: key,
        method: method
      }
    }).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
}
