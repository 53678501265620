export default function getDateFormat () {
  const today = new Date()
  const year = today.getFullYear().toString()
  let month = (today.getMonth() + 1).toString()
  if (month.length < 2) {
    month = '0' + month
  }
  let day = today.getDate().toString()
  if (day.length < 2) {
    day = '0' + day
  }
  return year + month + day
}
