export const media = {
  // 媒体文件夹
  IMAGE_FOLDER: '/media/image',
  GIF_FOLDER: '/media/gif',
  VIDEO_FOLDER: '/media/video',
  AUDIO_FOLDER: '/media/audio',
  // 媒体类型值 -> 媒体类型
  MEDIA_TYPE_NAME: {
    1: '图片',
    2: '动态图',
    3: '视频',
    4: '音频'
  },
  // 媒体类型
  MEDIA_TYPE: [
    { value: '1', label: '图片' },
    { value: '2', label: '动态图' },
    { value: '3', label: '视频' },
    { value: '4', label: '音频' }
  ]
}

export const blog = {
  // 默认图片路径
  WeChatPayQRcode: 'https://mrlinblog-1256212550.cos.ap-guangzhou.myqcloud.com/blog/WeChatPayQRcode.png',
  AliPayQRcode: 'https://mrlinblog-1256212550.cos.ap-guangzhou.myqcloud.com/blog/AliPayQRcode.png',
  ArticleDefautBackground: 'https://mrlinblog-1256212550.cos.ap-guangzhou.myqcloud.com/blog/default_backgroundpng.jpg'
}
