<template>
  <div id="admin-media-library" class="admin-contents">
    <div class="admin-contents-title admin-media-library-title">媒体库</div>
    <div class="admin-contents-wrap admin-media-library-wrap">
      <div class="media-selector">
        <el-select v-model="mediaTypeValue" clearable placeholder="默认全部媒体类型">
          <el-option
            v-for="item in mediaTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-date-picker
          v-model="mediaTime"
          value-format="yyyy-MM-dd"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions">
        </el-date-picker>
        <el-button
          type="primary"
          @click="queryMediaData"
          icon="el-icon-search">
          查询媒体
        </el-button>
        <el-upload
          action
          :show-file-list="false"
          :before-upload="mediaBeforeUpload"
          :http-request="mediaUploadRequest"
          :file-list="mediaUploadList"
          :multiple="false"
          :limit="1">
          <el-button
            type="success"
            :loading="mediaUploadLoading"
            icon="el-icon-plus">
            添加媒体
          </el-button>
        </el-upload>
        <el-button
          @click="deleteMedia"
          type="danger"
          size="medium"
          icon="el-icon-delete">
          删除媒体
        </el-button>
      </div>
      <el-table
        ref="multipleTable"
        :data="mediaData"
        tooltip-effect="dark"
        border
        stripe
        style="width: 100%"
        @selection-change="handleSelectionChange">
        <el-table-column
          type="selection"
          width="45">
        </el-table-column>
        <el-table-column
          prop="name"
          label="媒体文件名"
          width="300">
        </el-table-column>
        <el-table-column
          prop="mediaType"
          label="媒体类型"
          width="100">
        </el-table-column>
        <el-table-column
          prop="timestamp"
          label="创建日期"
          width="180">
        </el-table-column>
        <el-table-column
          prop="url"
          label="访问路径">
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="120">
          <template slot-scope="scope">
            <el-popover
              placement="left"
              trigger="click">
              <div class="media-preview">
                <img
                  v-lazy="scope.row.url"
                  alt="item.name"
                  v-if="['jpg', 'png', 'gif'].indexOf(scope.row.name.substring(scope.row.name.lastIndexOf('.') + 1).toLowerCase()) !== -1">
                <video
                  controls
                  v-else-if="['mp4', 'webm', 'ogg'].indexOf(scope.row.name.substring(scope.row.name.lastIndexOf('.') + 1).toLowerCase()) !== -1">
                  <source :src="scope.row.url">
                </video>
                <h3 v-else>暂不支持预览:
                  <a :href="scope.row.url" target="_blank">{{ scope.row.name }}</a>
                </h3>
              </div>
              <el-button
                type="text"
                size="small"
                slot="reference">
                查看
              </el-button>
            </el-popover>
            <el-button
              @click="handleDeleteSingleMedia(scope.row.mid)"
              type="text"
              size="small">
              删除媒体
            </el-button>
          </template >
        </el-table-column>
      </el-table>
      <div class="media-pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="mediaCurrentPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="mediaPageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="mediaTotal">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import COS from 'cos-js-sdk-v5'
import { media } from '@/utils/config'
import getCosAuth from '@/utils/cos_auth'
import getDateFormat from '@/utils/get_date'

export default {
  name: 'admin-media-library',
  data () {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      mediaData: [],
      mediaDeleteSelection: [],
      mediaCurrentPage: 1,
      mediaPageSize: 10,
      mediaTotal: 0,
      mediaTypeValue: null,
      mediaTime: [],
      mediaTypeOptions: media.MEDIA_TYPE,
      mediaUploadList: [],
      mediaUploadLoading: false
    }
  },
  created () {
    this.getMedia()
  },
  methods: {
    handleSelectionChange (val) {
      this.mediaDeleteSelection = []
      for (const item of val) {
        this.mediaDeleteSelection.push(item.mid)
      }
    },
    getMedia () {
      this.mediaData = []
      const queryData = {
        page: this.mediaCurrentPage,
        pageSize: this.mediaPageSize
      }
      if (this.mediaTypeValue) {
        queryData.mediaType = this.mediaTypeValue
      }
      if (this.mediaTime) {
        queryData.start = this.mediaTime[0]
        queryData.end = this.mediaTime[1]
      }
      this.$auth_http({
        url: this.$api.ADMIN_MEDIA_URL,
        method: 'GET',
        params: queryData
      }).then(response => {
        this.mediaTotal = response.data.total
        for (const item of response.data.result) {
          this.mediaData.push({
            name: item.name.substring(item.name.lastIndexOf('/') + 1),
            url: item.url,
            timestamp: item.timestamp,
            mediaType: media.MEDIA_TYPE_NAME[item.mediaType],
            mid: item.mid
          })
        }
      }).catch(error => {
        this.$message.error('媒体信息获取失败，' + error.toString() + '，请重试')
      })
    },
    deleteMedia () {
      if (this.mediaDeleteSelection.length > 0) {
        this.$confirm('此操作将删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$auth_http({
            url: this.$api.ADMIN_MEDIA_URL,
            method: 'DELETE',
            params: {
              mid: JSON.stringify(this.mediaDeleteSelection)
            }
          }).then(response => {
            if (response.status === 'success') {
              this.$message.success('媒体删除成功')
              this.getMedia()
              this.mediaDeleteSelection = []
            } else {
              this.$message.error(response.message)
            }
          }).catch(error => {
            this.$message.error('媒体信息删除失败，' + error.toString() + '，请重试')
          })
        })
      }
    },
    handleDeleteSingleMedia (mid) {
      this.mediaDeleteSelection = [mid]
      this.deleteMedia()
    },
    handleSizeChange (val) {
      this.mediaPageSize = val
      this.getMedia()
    },
    handleCurrentChange (val) {
      this.mediaCurrentPage = val
      this.getMedia()
    },
    mediaBeforeUpload (file) {
      const allowFileType = [
        'image/jpeg',
        'image/png',
        'image/gif',
        'video/mp4',
        'video/webm',
        'video/ogg',
        'audio/ogg',
        'audio/x-wav',
        'audio/webm',
        'audio/mp4'
      ]
      if (allowFileType.indexOf(file.type) === -1) {
        this.$message.error('只允许上传图片、动态图、视频和音频')
        return false
      }
      const isLt20M = file.size / 1024 / 1024 < 20
      if (!isLt20M) {
        this.$message.error('媒体大小不能超过50M')
        return false
      }
    },
    mediaUploadRequest (param) {
      const file = param.file
      let filename = ''
      let mediaType = 0
      const timestamp = new Date().getTime()
      if (file.type.indexOf('gif') !== -1) {
        filename = media.GIF_FOLDER
        mediaType = 2
      } else if (file.type.indexOf('image') !== -1) {
        filename = media.IMAGE_FOLDER
        mediaType = 1
      } else if (file.type.indexOf('video') !== -1) {
        filename = media.VIDEO_FOLDER
        mediaType = 3
      } else if (file.type.indexOf('audio') !== -1) {
        filename = media.AUDIO_FOLDER
        mediaType = 4
      } else {
        this.abort(file)
        return false
      }
      // 生成文件名
      filename += `/${getDateFormat()}/${timestamp}${file.name}`
      // 上传文件
      getCosAuth(filename, 'put').then(response => {
        if (response.data) {
          const cos = new COS({
            getAuthorization: function (options, callback) {
              callback(response.data.Signature)
            }
          })
          this.$message.info('媒体上传中...')
          cos.putObject({
            Bucket: response.data.Bucket,
            Region: response.data.Region,
            Key: filename,
            Body: file
          }, (err, data) => {
            if (err) {
              this.$message.error('媒体上传失败')
              this.mediaUploadList.pop()
              return false
            }
            this.$message.success('媒体上传成功')
            const url = process.env.VUE_APP_OSS_URL + filename
            // 保存媒体信息
            this.saveMediaInfo(filename, url, mediaType).then(response => {
              if (response.status === 'success') {
                this.$message.success('媒体信息保存成功')
                this.getMedia()
              } else {
                this.$message.error(response.message)
              }
            }).catch(error => {
              this.$message.error(`错误: ${error.toString()}`)
            })
            this.mediaUploadList.pop()
          })
        } else {
          this.$message.error('获取OSS签名失败')
          this.mediaUploadList.pop()
          return false
        }
      }).catch(error => {
        this.$message.error(`错误: ${error.toString()}`)
        this.mediaUploadList.pop()
        return false
      })
    },
    saveMediaInfo (name, url, mediaType) {
      return new Promise((resolve, reject) => {
        this.$auth_http({
          url: this.$api.ADMIN_MEDIA_URL,
          method: 'put',
          data: {
            name: name,
            url: url,
            mediaType: mediaType
          }
        }).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    queryMediaData () {
      this.mediaCurrentPage = 1
      this.getMedia()
    }
  }
}
</script>

<style lang="stylus" scoped>
#admin-media-library
  .admin-media-library-wrap
    .media-selector
      margin-bottom: 20px
      display: inline-flex
      .el-button
        margin-left: 10px
      .el-date-editor
        width: 410px
        margin-left: 10px
    .media-pagination
      width: 100%
      margin: 20px
      text-align: center

.media-preview
  width: 400px
  height: 250px
  display:flex
  align-items: center
  padding: 5px
  img
    max-height: 100%
    max-width: 100%
    margin: 0 auto
  video
    max-height: 100%
    max-width: 100%
    margin: 0 auto
</style>
